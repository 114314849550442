  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-31-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert sécheresse Salon de Provence</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Accompagnement de l’assuré suite à un sinistre sécheresse</h1>

<p>Les experts du cabinet RV Expertises vous accompagnent pour expertiser votre ouvrage fissuré suite à un sinistre sécheresse à Salon de Provence.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert sécheresse Salon de Provence" />
</div>


<p>Nous vous aiderons à déterminer les travaux de reprise les plus adaptés à votre problématique.</p>
<h2>Les désordres fissures suite à une sécheresse</h2>

<p>Après l’été, vous constatez l’apparition de fissures sur votre ouvrage. Les argiles constitutives du sol situé sous les fondations de votre maison sont très probablement les fautives ! </p>
<p>En effet, un épisode de sécheresse va favoriser le retrait de ces argiles.</p>
<p>Le sol va tasser et votre ouvrage également tasser, entraînant des fissures sur vos murs.</p>

<p>Dès lors, plusieurs recours existent pour trouver des solutions techniques afin de réparer de façon pérenne votre ouvrage.</p>

<h2>Que faire lorsque vous constatez des fissures après une sécheresse ?</h2>

<p>Votre contrat d’assurance prévoit la garantie Catastrophe Naturelle dès lors qu’une Arrêté de catastrophe naturelle pour retrait et gonflement des argiles est approuvé sur la commune de Salon de Provence.</p>

<p>Vous avez 10 jours pour déclarer votre sinistre à votre assurance.</p>

<p>L’assureur peut alors accepter ou refuser la garantie de votre sinistre, après passage de l’expert d’assurance.</p>

<h2>Que faire en cas de refus de garantie de sinistre sécheresse par votre assurance ?</h2>

<p>Si votre assurance refuse la garantie de votre sinistre sécheresse pour un motif que vous jugez infondé, vous avez alors la possibilité de vous défendre en mandatant un expert d’assuré sécheresse. Ce dernier vous représente afin de défendre vos intérêts.</p>

<p>L’expert d’assuré sécheresse est un professionnel du bâtiment indépendant, neutre et impartial.</p>
<p>Il expertise votre bâtiment et détermine si la sécheresse constitue le facteur déterminant d’apparitions des fissures.</p>

<h2>L’expertise sécheresse du cabinet RV Expertises</h2>

<p>Le cabinet RV Expertises est un cabinet d'experts spécialisé dans l’analyse des pathologies de la construction et l’accompagnement des assurés dans le cadre de dossiers sinistres sécheresse.</p>

<p>Nos experts mettent tout en œuvre pour mettre en place une expertise adaptée à vos besoins et défendre vos intérêts. Ils assurent un rôle de médiation et vous représentent auprès de l’assurance.</p>

<p>Si vous avez des doutes sur le refus de l’assurance, défendez-vous et faites-vous accompagner par un expert sécheresse du cabinet RV Expertises afin de défendre vos intérêts et d’obtenir la juste indemnisation de votre sinistre.</p>



<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demandez un devis</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-secheresse-salon-de-provence/' className='active'>Expert sécheresse Salon de Provence</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details